@import '~shared/styles/config';

.video {
    @include fit;

    z-index: 999;
    position: fixed;
    transition: 0.2s ease-out;

    .inner {
        height: 100%;
        width: 100%;

        @include centerer;

        &::before {
            content: '';
            padding-top: 56.25%;
            position: relative;
            height: 0;
            width: 100%;
            display: block;
        }

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;
            opacity: 0.7;
            transition: 0.2s ease-in-out;
            font-size: 36px;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    &.animate {
        background: rgba($www-space-gray, 0.95);
        transition: opacity 1s ease-in-out;
        // transition-delay: 0.2s;
        opacity: 0;
    }

    &.animate.visible {
        opacity: 1;

        .inner {
            height: auto;
            max-width: 85%;
            max-height: 85vh;
        }
    }
}
