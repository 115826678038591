@import '~shared/styles/config';

.archive {
    padding-top: 200px;

    .content {
        display: grid;
        grid-template-columns: 1fr fit-content(1290px) 1fr;
        grid-gap: 50px;
        padding: 40px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }

    .filter {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        color: $www-silver-gray;
        text-align: right;
    }

    .filter_title {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .year {
        font-size: 16px;
        margin-top: 2px;

        &:hover,
        &.active {
            color: $www-primary;
        }
    }
}
