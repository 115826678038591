@import '~shared/styles/config';

.card {
    display: flex;
    flex-direction: column;
    position: relative;

    &__content {
        padding: 20px 0;
        flex: 1;

        &.filled {
            padding: 20px;
            background: $www-space-gray;
        }
    }

    &__imgWrapper {
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            transition: opacity 0.5s ease;
        }
    }

    &__img {
        transition: transform 0.5s;
        transform: translate(0,0);
        will-change: transform, scale;
        width: 100%;
        object-fit: cover;
    }

    &__title {
        margin: 10px 0 0;

        a {
            background-image: linear-gradient(#c0c0c0, #c0c0c0);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 0 2px;
            transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
            padding: 0.1% 0px;
        }
    }

    &:hover & {
        &__imgWrapper::after {
            opacity: 0.3;
        }

        &__img {
            transform: scale3d(1.05, 1.05, 1) translate3d(0, 0, 0);
        }

        &__title a {
            background-size: 100% 2px;
        }
    }
}
