@import '~shared/styles/config';

.cta {
    text-align: center;

    > div {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    h2 {
        font-size: 32px;
    }

    .content {
        margin-top: 40px;
    }

    .buttons {
        justify-content: center;
    }

    @include viewport(desktop) {
        h2 {
            font-size: 40px;
        }
    }
}

@include viewport (mobileMax) {
    .cta {
        h2 {
            font-size: 28px !important;
        }
    }
}
