@import '~shared/styles/config';

.newsByCategoryContainer {

    .searchResults {
        p {
            margin: 0;
            padding: 0;
        }
    }

    .search {
        padding-top: 250px;

        input {
            color: white;
            background-color: #29353A;
        }
    }

    .newsByCategory {
        padding: 50px 0 20px 0;      
    }
}
