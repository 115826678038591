@mixin container() {
    margin: 0 auto;
}

@mixin font-headline-extra-large() {
    @include font-medium();

    font-size: 120px;
    line-height: 144px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-large() {
    @include font-medium();

    font-size: 96px;
    line-height: 101px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-medium() {
    @include font-medium();

    font-size: 72px;
    line-height: 76px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-regular() {
    @include font-medium();

    font-size: 48px;
    line-height: 51px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-small() {
    @include font-medium();

    font-size: 36px;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-extra-small() {
    @include font-medium();

    font-size: 26px;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin headline($size) {
    @if $size == 'xl' {
        @include font-headline-extra-large();
    }

    @else if $size == 'l' {
        @include font-headline-large();
    }

    @else if $size == 'm' {
        @include font-headline-medium();
    }

    @else if $size == 's' {
        @include font-headline-small();
    }

    @else if $size == 'xs' {
        @include font-headline-extra-small();
    }

    @else {
        @include font-headline-regular();
    }
}

@mixin font-tagline-large() {
    @include font-light();

    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-tagline-regular() {
    @include font-light();

    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.06em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-tagline-small() {
    @include font-light();

    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin tagline($size) {
    @if $size == 'l' {
        @include font-tagline-large();
    }

    @else if $size == 's' {
        @include font-tagline-small();
    }

    @else {
        @include font-tagline-regular();
    }
}

// Mixins for all font weight variants, including japan special font weight
@mixin font-family() {
    font-family: var(--font-family);
}

@mixin font-light() {
    font-weight: var(--font-weight-light);
}

@mixin font-regular() {
    font-weight: var(--font-weight-regular);
}

@mixin font-medium() {
    font-weight: var(--font-weight-medium);
}

@mixin font-semibold() {
    font-weight: var(--font-weight-semibold);
}


// @mixin font-content-regular() {
//     @include font-regular();
//     font-size: 22px;
//     line-height: 34px;
//     letter-spacing: -0.01em;
// }

// @mixin font-content-small() {
//     @include font-regular();
//     font-size: 18px;
//     line-height: 30px;
// }

@mixin content($size) {
    @if $size == 's' {
        @include font-content-small();
    }

    @else {
        @include font-content-regular();
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Useful for centering stuff inside relative container
@mixin centerer() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin bg-img() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

// Useful for YT videos and overlays f.x
@mixin fit() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

// Media queries
@mixin viewport($media) {
    @if $media == xsMax {
        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }

    @if $media == mobileMax {
        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }

    @if $media == mdMax {
        @media only screen and (max-width: $screen-md-max) {
            @content;
        }
    }

    @if $media == xs {
        @media only screen and (min-width: $screen-xs) {
            @content;
        }
    }

    @if $media == mobile {
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    }

    @else if $media == tablet {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    }

    @else if $media == tabletmax {
        @media only screen and (max-width: $screen-md) {
            @content;
        }
    }

    @else if $media == tabletmin {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    }

    @else if $media == desktop {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    }

    @else if $media == desktopMax {
        @media only screen and (max-width: $screen-lg) {
            @content;
        }
    }

    @else if $media == xlargeDesktopMax {
        @media only screen and (max-width: $screen-xlg) {
            @content;
        }
    }

    @else if $media == xlargeDesktopUp {
        @media only screen and (min-width: $screen-xlg) {
            @content;
        }
    }

    @else if $media == max1440 {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    }

    @else if $media == oldIE {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            @content;
        }
    }

    @else if $media == IE {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            @content;
        }
    }
}

@mixin container() {
    margin: 0 auto;
    padding: 0 $container-gutter-mobile;
    max-width: $page-width + $container-gutter-mobile * 1;

    @include viewport(mobile) {
        max-width: $page-width + $container-gutter-mobile * 2;
    }

    @include viewport(tablet) {
        padding: 0 $container-gutter-tablet;
        max-width: $page-width + $container-gutter-tablet * 2;
    }

    @include viewport(desktop) {
        padding: 0 $container-gutter-desktop;
        max-width: $page-width + $container-gutter-desktop * 2;
    }
}

// Used with position relative on parent
@mixin triangle-corners($color) {
    &::before {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $color;
        border-right: 8px solid $color;
    }

    &::after {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid $color;
        border-right: 8px solid $color;
    }
}

@mixin gradient($direction: bottom, $width: 100%, $height: 50%) {
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: $width;
        height: $height;
        background-image: linear-gradient(to $direction, rgba(#000, 0%), rgba(#000, 0.38) 44%, rgba(#000, 0.67) 100%);
    }
}

@mixin gradient-transition($direction: bottom, $position: left, $width: 100%, $height: 50%) {
    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        // left: 0;
        width: $width;
        height: $height;
        background: linear-gradient(to $direction, rgba(#000, 0%), rgba(#000, 0.28) 44%, rgba(#000, 0.52) 100%);

        @if $position == right {
            right: 0;
            left: auto;
        }
    }

    &::after {
        // width: 50%;
        @if $direction == bottom {
            height: $height * 2;
        }

        @else if $direction == left or $direction == right {
            width: $width * 2;
        }

        transition: opacity 0.2s linear;
        opacity: 0;
    }

    &:hover::after {
        opacity: 1;
    }
}

@mixin styled-scrollbar($track, $thumb) {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: $thumb;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: $track;
    }

    scrollbar-color: $thumb $track;
    scrollbar-width: thin;
}
