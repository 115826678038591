@import '~shared/styles/config';

.featured {
    background: $www-night-black;
    padding: 30px;

    &__content {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #000;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__img {
        margin-right: 20px;
    }
}