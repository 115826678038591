
@import '~shared/styles/config';

.imageList {
    width: 1290px;
    margin-left: auto;
    margin-right: auto;
    // padding-bottom: 200px;
    // margin-top: 80px;

    @include viewport (max1440) {
        width: auto;
    }

    @include viewport (mobileMax) {
        width: auto;
        padding-bottom: 0;
    }
}
