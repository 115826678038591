
@import '~shared/styles/config';

.frame {
    height: 100%;

    h2,
    h3 {
        text-transform: uppercase;
    }

    h2 {
        font-size: 26px;
        @include font-medium();
    }

    h3 {
        font-size: 16px;
        margin-top: 8px;
    }

    p {
        font-size: 20px;
        @include font-light();
    }

    .content {
        padding: 15px;
    }

    .img {
        padding: 1px;
        width: 100%;
    }
}

.frame__inner,
.frame__inner + div {
    background: var(--dlpBG);
}

@include viewport(tablet) {
    .frame .content {
        padding: 25px;
    }
}
