@import '~shared/styles/config';

.frameContainer {
    max-width: 630px;
    margin-top: 20px;
    padding: 50px 60px;
    background-color: rgba(255, 255, 255, 0.9);
    // max-width: 450px;
    margin: 0 auto;
    color: #212121;

}

.emailSignup {

    h3 {
        margin: 10px 0 40px 0;
        font-size: 26px;
    }

    .disclaimer  {
        color: #777;
        padding: 0;
        margin:0px;
        font-size: 14px;
    }

    .message {
        padding: 10px;
        font-size: 20px;

        &.success {
            svg {
                color: $alert-success;
                margin-right: 10px;
            }
        }

        &.warning {
            svg {
                color: $alert-warning;
                margin-right: 10px;
            }
        }   
    }

    .backButton {
        margin-top: 20px;
    }
    
    input,
    input:focus {
        font-size: 1.125rem;
        line-height: 1.15rem;
        color: #777777;
        width: 100%;
        height: 100%;
        padding: 12px 16px 8px;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(192, 192, 192, 0.4);
        outline: none;
        background-color: rgba(191, 191, 191, 0.7);
        margin-bottom: 20px;
        border-bottom: 1px solid #f67c0f;
    }

    input[type=button], input[type=submit] {
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        padding: 15px 30px;
        width: 100%;
        font-size: 15px;
        height: 54px;
        text-transform: uppercase;
        min-width: 245px;
        letter-spacing: 0.1em;
        display: inline-grid;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        line-height: 0;
        font-weight: 600;
        clip-path: polygon(0% 0,0 0%,100% 0%,100% 0,100% calc(100% - 10px),calc(100% - 10px) 100%,0 100%,0% calc(100% - 10px) );
        background-color:  #f67c0f;
        -webkit-appearance: none;
        border-radius: 0;        

        // background: linear-gradient(180deg,#30B2E6 0%,#2B9ECC 100%),#30B2E6;
        color: #fff;
        border: 0;
        white-space: nowrap;
        cursor: pointer;
        // transition: all 0.2s;

        &:hover {
            background: linear-gradient(180deg,#f67c0f 0%,#f3892c 100%),#df781e;
        }
    }
}
