@import '~shared/styles/config';

.live_event {
    padding: 160px 0;
    background-repeat: no-repeat;

    @include viewport(tablet) {
        background-attachment: fixed;
        background-size: cover;
        padding: 230px 0;
    }

    h2 {
        margin-bottom: 10px;
        @include font-semibold();
        font-size: 8vw;

        @include viewport(mobile) {
            font-size: 48px;
        }
    }

    h3 {
        margin-top: 0;
        text-transform: uppercase;
        @include font-medium();
        font-size: 6vw;

        @include viewport(mobile) {
            font-size: 24px;
        }
    }

    p {
        font-size: 18px;

        @include viewport(mobile) {
            font-size: 20px;
        }
    }

    .event_logo_wrapper {
        position: relative;
    }

    .event_logo {
        display: none;

        @include viewport(mobile) {
            display: block;

            @include centerer;
        }
    }

    .awards, .challanges, .story, .participate {
        margin-top: 40px;
        margin-bottom: 20px;
        line-height: 1.7;

        @include viewport(tablet) {
            margin-top: 130px;
        }

        .border {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

    .story_wrapper {
        padding-top: 0;

        @include viewport(tablet) {
            padding-top: 20px;
        }

        @media only screen and (min-width: $min-1440) {
            padding-top: 80px;
        }
    }

    .story_info {
        .inner {
            background: rgba(23, 23, 23, 0.9);
            padding: 20px;

            @include viewport(tablet) {
                padding-top: 60px;
                min-height: 450px;
            }

            line-height: 1.7;

            .text_wrapper {
                padding: 0 10px;

                @include viewport(tablet) {
                    padding: 60px 45px 100px 20px;
                }
            }

            .img_wrapper {
                position: relative;
                height: 100%;
                display: none;

                @include viewport(tablet) {
                    display: block;
                }
            }

            img {
                width: auto;
                left: 0%;

                // @media only screen and (min-width: $min-1440) {
                //     left: 0%;
                // }

                @media only screen and (min-width: $min-1440) {
                    // width: 100%;
                    // height: 120%;
                    position: absolute;
                    bottom: 30px;
                    left: 5%;
                }
            }

            h4 {
                text-transform: uppercase;
                @include font-medium();
                font-size: 28px;
                margin-bottom: 30px;
            }
        }
    }

    .heading {
        font-size: 28px;
        text-transform: uppercase;
        margin-bottom: 0;
        @include font-semibold();
    }

    .event_ends {
        p {
            text-align: left;

            @include viewport(tablet) {
                text-align: right;
            }

            font-size: 19px;
            text-transform: uppercase;
            margin: 0;
        }
    }

    .box {
        padding-bottom: 20px;

        @include viewport(tablet) {
            display: flex;
        }

        .inner {
            font-size: 18px;
            position: relative;
            background: rgba(77, 29, 58, 0.5) url('../../../../static/img/live-event/avatar-small.png') no-repeat 94% 4%;
            padding: 0 15px;
            padding-top: 35px;
            padding-bottom: 35px;
            text-align: center;

            .title {
                text-transform: uppercase;
            }

            p {
                font-size: 18px;
            }

            img {
                margin: 0 auto;
            }

            .points {
                span {
                    font-size: 15px;
                    color: #8c8e90;
                    background: url('../../../../static/img/live-event/event-logo-xs.png') no-repeat 0% 0%;
                    padding-left: 40px;
                }
            }
        }
    }

    .awards_box {
        .inner {
            display: flex;
            margin-bottom: 40px;

            .content {
                padding-left: 20px;

                h4 {
                    margin: 0 0 10px;
                    @include font-medium();
                    text-transform: uppercase;
                    font-size: 18px;
                }

                p {
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }
}

.past_challenges {
    margin: 40px 0 120px;
}

.past_challenge_item {
    .inner {
        h4 {
            @include font-medium();
            font-size: 18px;
            margin: 20px 0 10px;
            text-transform: uppercase;
        }

        p {
            margin: 0;
        }
    }
}

.cta {
    margin-top: 20px;
}

.button {
    height: 45px;
    padding: 0 20px;
    font-size: 16px;
    margin-left: auto;
    // border: 1px solid red;

    @include viewport(mobileMax) {
        // display: none;
        // border: 1px solid green;
    }
}
