@import '~shared/styles/config';

.alert {
    font-size: 18px;
    padding: 24px 25px 25px;
    display: grid;
    grid-template-columns: 52px 1fr;
    grid-gap: 25px;
    @include font-regular();

    .icon svg {
        vertical-align: -7px;
    }

    &.success {
        background: rgba($alert-success, 0.1);
        border: 1px solid $alert-success;
        color: $alert-success;

        .icon svg {
            width: 52px;
            height: 52px;
        }
    }

    &.warning {
        background: rgba($alert-warning, 0.1);
        border: 1px solid $alert-warning;
        color: $alert-warning;

        .icon svg {
            width: 52px;
            height: 52px;
        }
    }

    &.error {
        background: rgba($alert-error, 0.1);
        border: 1px solid $alert-error;
        color: $alert-error;

        .icon svg {
            width: 52px;
            height: 52px;
        }
    }
}
