@import '~shared/styles/config';

.tags {
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: rgba(33,33,33,0.8);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    ul, li {
        @include reset-list;
        display: inline-block
    }

    li {

        padding: 0px  5px;

        a {
            background-color: #29353A;
            border-radius: 10px;
            padding: 5px 10px 4px 10px;
            text-transform: uppercase;
            font-size: 15px;
            color: #00aeff;
        }
    }

    .search {
        background-color: #29353A;
        border-radius: 15px;
        padding-right: 20px;;

        input {
            @include reset-button;

            color: white;
            border: none;
            padding: 5px 10px 5px 15px;
        }

        button {
            @include reset-button;

            svg {
                color: #C0C0C0;
            }

            &:hover {
                cursor: pointer;

                svg {
                    color: #fff;;
                }
            }

        }
        
    }
}
