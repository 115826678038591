@import '~shared/styles/config';

$brand-invalid: #fb5d5d;

// Default Theme
:root {
    --sf-background-color: #29353a;
    --sf-text-color: #fff;
    --sf-error-color: #f44336;
    --sf-disabled-color: #636363;
    --sf-input-border-color: 192,192,192;
    --sf-input-border-hover-color: #dcdcdc;
    --sf-input-hover-focus-color: #30b2e6;
    --sf-animation-duration: 0.3s;
    --sf-transition: cubic-bezier(0.4, 0, 0.2, 1);
    --sf-terms-text-color: #fff;
    --sf-terms-button-color: #30b2e6;
    --sf-terms-checkbox-color: rgba(255, 255, 255, 0.4);
}

.signup {
    color: white;
    margin-top: 40px;
    
    // Light Theme
    &.light {
        --sf-background-color: rgba(119, 119, 119, 0.2);
        --sf-text-color: #212121;
        --sf-input-hover-focus-color: #29353A;
        --sf-terms-text-color: #212121;
        --sf-terms-checkbox-color: rgba(119,119,119, 0.95);
    }

    // Quadrant Theme
    &.quadrant {
        --sf-input-hover-focus-color: #fad74d;
        --sf-terms-button-color: #fad74d;
    }

    .globalError {
        margin-bottom: 30px;
    }

    .formGroup {
        // height: 130px;
        padding: 0;
        margin: 0 auto;
        position: relative;

        &.slim {
            height: 70px;
        }

        &.checkbox {
            display: inline-grid;
            grid-auto-flow: column dense;
            grid-gap: 3px;
        }

        &.extra {
            display: none;
            margin-bottom: 40px;
        }

        &.hidden {
            display: none;
        }

        .showHidePass {
            position: absolute;
            z-index: 1;
            right: 35px;
            top: 18px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            svg {
                color: var(--sf-terms-button-color);
                font-size: 1.5rem;
            }

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
            }
        }

        // label {
        //     font-family: $font-semibold;
        //     // color: #404040;
        //     text-transform: uppercase;
        // }

        // input[type='checkbox'] {
        //     height: unset;
        //     width: unset;
        //     float: left;
        // }

        // .formControl {
        //     // background-color: cyan;

        //     &.isInvalid {
        //         // color: blue;
        //         // background-color: $brand-invalid;
        //     }
        // }

        .invalidFeedback {
            color: $brand-invalid;
            display: block;
            height: 30px;
        }

        .terms {
            margin-left: 30px;
            display: block;
            text-transform: none;
            color: var(--sf-terms-text-color);

            .terms__button {
                @include reset-button;

                // color: $www-primary;
                color: var(--sf-terms-button-color);
                cursor: pointer;
                text-transform: inherit;
            }
        }

        .submit {
            display: block;
            margin: 0 auto;
            width: 100%;

            &[disabled] {
                background: #619dc4;
                cursor: default;
            }
        }
    }

    .login {
        text-align: center;
        margin: 15px 0;
        font-size: 16px;
        letter-spacing: 0.06em;
        font-weight: $font-regular;
        color: var(--sf-terms-text-color);

        a {
            color: var(--sf-terms-button-color);
        }
    }

    .upSell {
        h3 {
            // text-align: center;
            font-size: 22px;
        }

        .ctaContainer {
            background: url(https://web.ccpgamescdn.com/aws/eveonline/images/misc/bronze-pack.jpg) no-repeat top center;
            min-height: 185px;
            display: flex;
            align-items: flex-end;
            padding: 10px;
            margin-bottom: 25px;
        }

        .cta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            p {
                font-family: $font-semibold;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                padding: 0 0 0 20px;
            }

            button {
                padding: 0 20px;
            }
        }

        .benefits {
            ul {
                padding: 0;
                margin-top: 20px;

                li {
                    list-style: none;
                    margin-top: 5px;
                    color: #cfcfcf;
                    position: relative;
                    padding-left: 18px;
                    font-size: 18px;

                    @include viewport(mobile) {
                        font-size: 18px;
                    }

                    em {
                        // color: #30b2e6;
                        font-style: normal;
                    }

                    &::before {
                        content: '\25A0';
                        color: #30b2e6;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                        position: relative;
                        top: -2px;
                    }
                }
            }
        }
    }

    .recaptchaText {
        height: auto;
        color: #6b6b6b;
        font-size: 13px;
        padding-top: 15px;
    
        @include viewport(tablet) {
            padding-right: 60px;
        }
    
        a {
            // color: var(--sf-terms-button-color);
            color: #c0c0c0;
    
            &:hover {
                // color: #488abb;
                filter: brightness(1.1);
            }
        }
    }

    .recaptchaBadge {
        display: none;
    }
}
