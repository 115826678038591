@import '~shared/styles/config';

.download {
    background: #000 url('//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/download.jpg') no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
    padding: 90px 0 230px;

    @include viewport(desktop) {
        padding-top: 230px;
    }

    h2 {
        font-size: 48px;
        line-height: 1;

        @include viewport(mobileMax) {
            font-size: 8vw;
        }

        @include font-semibold();
    }
}

.sysReq {
    h4 {
        @include font-medium();
        font-size: 22px;
    }

    .tabs {
        margin: 0 0 20px;
        padding: 0;
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid $brand-higlight;

        li {
            list-style-type: none;
            float: left;
            margin-right: 15px;

            div {
                background: none;
                border: none;
                color: #c1c1c1;
                padding: 5px 20px 15px;
                letter-spacing: 0.2em;
                @include font-medium();
                font-size: 16px;
                text-transform: uppercase;

                svg {
                    margin-right: 8px;
                }

                &:hover {
                    cursor: pointer;
                    color: #fff;
                }

                &.current {
                    // border-bottom: 6px solid $brand-higlight;
                    position: relative;
                    color: #fff;

                    &::after {
                        content: '';
                        // background-color: $brand-higlight;
                        position: absolute;
                        bottom: 2px;
                        left: 44%;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 6px solid $brand-higlight;
                    }

                    &::before {
                        content: '';
                        background-color: $brand-higlight;
                        width: 100%;
                        position: absolute;
                        height: 6px;
                        left: 0;
                        bottom: -4px;
                    }
                }
            }
        }
    }

    .tableShow {
        position: relative;
        transition: all 2s cubic-bezier(0.43, 1, 0.62, 1);
        opacity: 1;
        // animation: showMe 0.5s;
        animation: FadeInRight 0.5s;
    }

    .table_bg {
        position: absolute;
        bottom: 15px;
        right: 30px;
        opacity: 0.025;
        font-size: 225px;

        &.apple {
            bottom: 20px;
        }
    }

    table {
        border-spacing: 0;
        width: 100%;
        // position: absolute;
        top: 0;
        left: 0;

        thead {
            tr:hover {
                background: none;
            }

            th {
                &:first-child {
                    width: 16%;
                }

                @include font-semibold();
                background-color: rgba(59, 62, 68, 0.5);
            }
        }

        td,
        th {
            background-color: rgba(23, 27, 35, 0.8);
            padding: 5px 10px;

            @include viewport(mobile) {
                padding: 20px 30px;
            }

            text-align: left;
        }

        tr:hover {
            background-color: #2d3339;
        }

        td {
            @include font-light();

            // border-bottom: 1px solid rgba(59, 62, 68, 0.5);

            &:first-child {
                @include font-medium();
            }

            &:nth-child(1),
            &:nth-child(2) {
                border-right: 1px solid rgba(59, 62, 68, 0.5);
            }
        }
    }
}

@keyframes FadeInRight {
    from {
        opacity: 0;
        transform: translateX(10px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes showMe {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
