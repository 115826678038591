@import '~shared/styles/config';

.featured {
    // margin: 80px 0;

    .wrapper {
        width: 1290px;
        margin: 0 auto;
        position: relative;

        @include viewport (max1440) {
            width: 100%;
        }
    }

    .link img {
        transition: 0.2s ease-in-out;

        &:hover {
            filter: brightness(1.2);
        }
    }

    .content {
        max-width: auto;
        padding-top: 20px;
    }

    h2 {
        font-size: 32px;
        letter-spacing: 0.025em;
    }

    h3 {
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 5px;
    }

    @include viewport(tablet) {
        .content {
            position: absolute;
            left: 50px;
            bottom: 50px;
            width: 100%;
            max-width: 75%;
        }

        p {
            font-size: 20px;
        }
    }

    @include viewport(desktop) {
        .content {
            max-width: 50%;
        }
    }

    @include viewport(mobileMax) {
        .wrapper {
            padding: 0 15px;
        }
    }
}
