@import '~shared/styles/config';

.content {
    .headline {
        margin-top: 170px;

        @media only screen and (max-width: '1080px') {
            margin-top: 100px;
        }
    }

    .tagline {
        margin: 20px auto 70px;
        text-transform: unset;
        max-width: 700px;

        @media only screen and (max-width: '1080px') {
            margin: 20px auto 40px;
        }
    }

    .cta {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        justify-items: center;
        margin-top: 60px;

        .link {
            transition: 0.2s ease-in-out;

            &:hover {
                opacity: 0.9;
            }

            .background {
                padding: 20px;
                width: 90vw;
                height: 48vw;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;

                @include box('small', 1, 0, 0, 0);

                .button {
                    align-self: flex-end;
                    margin-left: auto;
                    max-width: 180px;
                }
            }
        }
    }

    // @include viewport(tablet) {
    //     .cta {
    //         grid-template-columns: 1fr;
    //         grid-gap: 10px;

    //         .link {
    //             .background {
    //                 width: 46vw;
    //                 height: 26vw;
    //             }
    //         }
    //     }
    // }

    @include viewport(mobile) {
        .cta {
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;
            min-height: 335px;

            .link {
                width: 100%;

                .background {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
