@import '~shared/styles/config';

:root {
    --news-social-color: #C0C0C0;
}

.newsDetail {
    // margin-top: 300px;

    .grid {
        padding: 0px 0 40px 0;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 30px;

        .detail {
            margin-top: -80px;

            h2 {
                position: relative;
            }
        }
    }

    .authAndShare {
        margin-top: 30px;
        display: flex;
    }

    .newsBackground {
        padding: 100px 0;
        min-height: 700px;
        position: relative;
        background-color: rgba(#000, 0.2);
        background-blend-mode: overlay;
    
        // Stars in bottom of hero area (use when image does not contain stars)
        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            width: 100%;
            height: 50%;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,1) 15%, rgba(0,0,0,0) 100%);
        }        


        // Stars in bottom of hero area (use when image does not contain stars)
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 197px;
            background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png');
            background-repeat: repeat-x;
            pointer-events: none;
        }        
    }    
}


