@import '~shared/styles/config';

.videoWrapper {
    .close {
        position: absolute;
        top: -45px;
        right: 0;
        z-index: 1;
        opacity: 0.7;
        transition: 0.2s ease-in-out;
        font-size: 36px;
        cursor: pointer;
        transform: rotate(90deg);
        color: #fff;

        &:hover {
            opacity: 1;
        }
    }

    .player {
        @include fit;
    }

    h5 {
        @include font-medium();
        font-size: 24px;
        text-transform: uppercase;
        text-align: left;
        letter-spacing: 1.5px;
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
        margin-top: 30px;
        text-align: left;
    }

    .btn {
        span {
            display: flex;
            align-items: center;
        }

        .btn_icon {
            margin-right: 10px;
        }
    }

    .wrapper {
        overflow: hidden;
        position: relative;
        margin-top: 20px;
        outline: 1px solid rgba(140, 140, 140, 0.21);

        &:hover {
            img {
                filter: brightness(1.2);
            }

            .icon {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        img {
            width: 100%;
            cursor: pointer;
            position: relative;
            transition: 0.2s ease-in-out;
        }

        .icon {
            @include centerer;

            transition: 0.2s ease-in-out;
            cursor: pointer;
        }
    }
}
