@import '~shared/styles/config';

.table {
    width: 100%;

    // /* Zebra striping */
    // tr:nth-of-type(odd) {
    //     background: #444;
    // }

    .sorted {
        color: white;

        span {
            margin-left: 5px;
        }

        svg {
            color: #00aeff;
            width: 8px;
        }
    }
}

@include viewport(mobile) {
    .table {
        border-collapse: separate;
        border-spacing: 0 1px;

        tr {
            min-height: 45px;
        }

        td, th {
            padding: 10px 6px;
            text-align: left;
        }
    }
}

@include viewport(mobileMax) {
    .table {
        display: block;

        thead, tbody, th, td, tr {
            display: block;
        }

        // hide table headers
        // but display them for a11y
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        td {
            border: none;
            min-height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            text-align: right;

            &.shortText {
                div { display: none; }

                &::after {
                    content: attr(data-td);
                }
            }

            &:not(:last-child) {
                margin-bottom: 1px;
            }

            &::before {
                content: attr(data-th);
                white-space: nowrap;
            }
        }
    }
}
