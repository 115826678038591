@import '~shared/styles/config';

// CTA button animation
@import 'CTA.scss';

.hero {
    padding: 140px 0;
    position: relative;
    overflow: hidden;

    @include bg-img;

    // TODO: Remove below when we get new key art?
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 168px;
        background-image: linear-gradient(to bottom, rgba(#000, 0.35), transparent);
        z-index: 1;
    }

    // Stars in bottom of hero area (use when image does not contain stars)
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 197px;
        background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
        background-repeat: repeat-x;
        pointer-events: none;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    h1,
    h2 {
        line-height: 1.1;
    }

    h1 {
        width: 100%;
        max-width: 700px;
    }

    h2 {
        margin-top: 10px;
    }

    .buttons {
        margin-top: 20px;
    }

    .btn {
        margin-top: 10px;
        margin-right: 30px;
    }

    .returningPlayer {
        visibility: hidden;

        p {
            line-height: 1.2;
        }

        a {
            color: #fff;
            margin-left: 2px;

            &:hover {
                color: #00aeff;
            }
        }
    }

    &.colorTheme {
        h1 {
            color: $www-theme-h1;
        }

        h2 {
            color: $www-theme-h2;
        }
    }
}

/**********************************
    @Media queries
**********************************/

@include viewport(tablet) {
    .hero {
        padding: 220px 0;

        .buttons {
            margin-top: 30px;
        }

        .returningPlayer {
            text-shadow: unset;
        }

        &.HeroCenter .container {
            margin-bottom: 100px;
        }
    }
}

@include viewport(desktop) {
    .hero {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /*******************************
        - Hero alignment starts
        *******************************/
        &.HeroRight {
            text-align: right;

            h1 {
                margin-left: auto;
                margin-right: -4px;
            }

            .btn {
                margin-right: 0;
                margin-left: 30px;
            }
        }

        &.HeroCenter {
            text-align: center;

            h1 {
                margin: 0 auto;
            }

            .btn {
                margin: 10px 15px 0;
            }
        }

        /*******************************
        - Hero alignment ends
        *******************************/

        .container {
            width: 100%;
        }
    }
}
