@import '~shared/styles/config';

.nebula {
    background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/nebula-lg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

@include viewport (IE) {
    .nebula {
        margin-top: 0;
    }
}

@include viewport (mobileMax) {
    .nebula {
        background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/sm-nebula.jpg');
    }
}
