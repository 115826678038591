@import '~shared/styles/config';

.content {
    margin-top: 150px;

    @media only screen and (max-width: '1080px') {
        margin-top: 100px;
    }

    .top {
        text-align: center;

        .tagline {
            margin: 20px auto 60px;
            text-transform: unset;
            max-width: 700px;

            strong {
                font-weight: 500;
            }
        }

        .companion {
            img {
                display: unset;
                margin-right: 20px;
                height: 60px;
            }
        }
    }

    .bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: left;
        padding-top: 120px;

        @media only screen and (max-width: '798px') {
            grid-template-columns: 1fr;
            padding-top: 60px;
        }

        .left {
            // margin-right: 90px;
            margin-left: auto;
            margin-top: -20px;

            @media only screen and (max-width: '798px') {
                margin: 0 auto;
            }

            p {
                text-transform: uppercase;
                font-weight: 500;
            }

            ul {
                font-size: 22px;
                font-weight: 300;
                padding: 0;

                li {
                    list-style: none;
                    padding-left: 20px;

                    &::before {
                        content: '\25A0';
                        color: #30b2e6;
                        font-size: 60%;
                        font-weight: bold;
                        display: inline-block;
                        width: 1.4em;
                        margin-left: -1em;
                        position: relative;
                        top: -4px;
                        left: -6px;
                    }
                }
            }
        }

        .right {
            margin: 60px auto 0;
            transition: 1s transform, 0.5s opacity;
            transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
            transition-delay: 0.1s;

            &.hidden {
                transform: translateY(180px);
                opacity: 0;
            }

            &.visible {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}
