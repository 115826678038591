@import '~shared/styles/config';

// .accountNav {
//     // display: grid;
//     // grid:
//     //     '. upgrade nav' auto
//     //     '. . .' 20px
//     //     'menu menu menu' auto
//     //     / auto auto auto;
// }

// .callToAction {
//     grid-area: upgrade;
//     text-transform: uppercase;
//     color: #fc0;
//     padding-right: 10px;
//     cursor: pointer;
//     font-size: 14px;
//     letter-spacing: normal;
//     align-self: center;

//     @include font-medium();

//     &:focus {
//         outline: none;
//     }

//     @media only screen and (max-width: $max-1280) {
//         display: none;
//     }
// }

.list {
    grid-area: menu;
    background-color: white;
    letter-spacing: 0.05em;

    @include font-regular();

    padding: 20px;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease-in-out;
    top: 10px;
    position: relative;

    .userMobile {
        color: $www-space-gray;
        padding-bottom: 10px;
        text-transform: uppercase;
        text-align: right;

        @include viewport('tablet') {
            display: none;
        }
    }

    .option {
        .text {
            color: #202020;
            opacity: 0;
            display: flex;

            .logo {
                padding-left: 5px;
                color: #3581b9;
            }

            &:hover {
                color: #3581b9;
            }
        }

        &:not(:last-child) {
            .text {
                padding-bottom: 10px;
            }
        }
    }

    &.open {
        height: 100%;
        opacity: 1;
        pointer-events: all;

        .option {
            .text {
                opacity: 1;
                transition: opacity 0.3s linear 0.3s;
                // transition-delay: 0.3s;
            }
        }
    }
}

.userType {
    grid-area: upgrade;
    width: 26.4px;
    display: none;

    @include viewport('tablet') {
        display: block;
    }
}

.navButton {
    align-self: flex-end;
    display: flex;
    color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    background: none;
    margin: 0 0 0 auto;

    &:focus {
        outline: none;
    }

    .user {
        padding: 0 10px;
        text-transform: uppercase;
        font-size: 14px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
        @include font-medium();
    }

    .chevron {
        display: none;
    }

    .loggedIn {
        width: 18px;
    }

    @include viewport('tablet') {
        .user {
            display: block;
        }

        .chevron {
            display: block;
        }

        .loggedIn {
            display: none;
        }
    }
}
