@import '~shared/styles/config';

.accountmenu {
    position: relative;
    overflow: visible;
    display: inline-flex;
    font-size: 15px;
    letter-spacing: 0.05em;
    z-index: 24;
    padding-top: 8px;
    cursor: pointer;

    &.colorTheme {
        .userIcon {
            fill: $www-theme-highlight;
        }

        .icon {
            color: $www-theme-highlight;
        }

        ul .active a,
        ul a:hover {
            color: $www-theme-highlight !important;
        }
    }

    .userName {
        padding-top: 1px;
        padding-left: 10px;
    }

    .userIcon {
        display: inline-block;
        fill: #30b2e6;
        transition: 0.2s ease-in-out;
    }

    .arrow {
        padding-top: 2px;
    }

    @media only screen and (max-width: $max-1440) {
        .userName {
            display: none;
        }

        .arrow {
            position: relative;
            top: -1px;
        }
    }

    .selected {
        display: inline-flex;
        margin-left: 10px;
        text-transform: uppercase;
        @include font-semibold();

        &:focus {
            outline: none;
        }

        .loginText {
            margin-top: -1px;
            padding-left: 10px;
        }
    }

    ul {
        position: absolute;
        display: inline-block;
        transform: translateY(10px);
        transition: 0.2s ease-in-out;
        opacity: 0;
        visibility: hidden;
        background: #212121; // TODO: Change background when moved to Navigation
        padding: 25px 20px;
        left: auto;
        right: 0;
        top: 25px;
        white-space: nowrap;

        @media only screen and (max-width: $max-1280) {
            right: -5px;
        }

        // Prevent opacity flicker on hover
        &::after {
            content: '';
            position: absolute;
            top: -40px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        li {
            display: block;
            margin-top: 15px;
            margin-left: 0;

            &:nth-child(1) {
                margin-top: 0;
            }
        }

        a {
            color: #fff;
            transition: 0.2s ease-in-out;
        }

        .active a,
        a:hover {
            color: $www-primary;
        }
    }

    .icon {
        margin: 0 0 0 8px;
        color: #30b2e6;
        display: inline;
        transition: 0.4s ease-in-out;
    }

    &:hover,
    &:focus {
        ul {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            li:hover {
                color: white;
            }
        }

        .icon {
            transform: scaleY(-1); // Flip arrow
        }

        .userIcon {
            filter: brightness(120%);
        }
    }
}
