@import '~shared/styles/config';

.gradient {
    position: relative;
    z-index: 1;

    img {
        position: absolute;
        top: -21vw;
        width: auto;
        min-width: 100%;
        filter: brightness(0.8);
    }
}

@include viewport (mobileMax) {
    .gradient {
        display: none;
    }
}
