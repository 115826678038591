@import '~shared/styles/config';

$seo-page-card-color: rgba(33, 33, 33, 0.85);

.seopage {
    padding-top: 40px;
    padding-bottom: 120px;

    // ------- SHARED --------
    .frame {
        background-color: $seo-page-card-color;
    }

    .section {
        padding: 40px 0;
    }

    @include viewport(mdMax) {
        .nopad_mobile {
            padding: 0;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
    }

    h2.headingBorder {
        position: relative;
        font-size: 36px;
        padding-left: 25px;
        &:after {
            content: "";
            background: #30B2E6;
            position: absolute;
            top: 6px;
            bottom: 5px;
            left: 0;
            width: 6px;
        }
    }



    &:last-child {
        margin-bottom: 0;
    }

    // ------- SECTION 1 --------
    .section1 {
        .container {
            display: flex;
            flex-direction: column;
        }

        .image {
            position: relative;
        }

        .heading {
            margin-top: 20px;
        }

        .content {
            position: relative;
            // margin-top: -90px;
        }

        .button {
            margin-top: 40px;
            margin: 0;

            .buttonContainer {
                margin: 0;
                justify-content: left;
            }
        }
    }

    // ------- SECTION 2 --------
    .section2 {
        // display: grid;
        // grid-template-columns: 1fr 1fr;

        .contentContainer {
            display: flex;
            justify-content: flex-end;

            .side {
                background-color: rgba(33, 33, 33, 0.85);
            }
                       
            .content {
                padding: 30px;
            }
        }

        .content {
            padding: 30px;
        }
    }

    // ------- SECTION 3 --------
    .section3 {
        .heading {
            line-height: 50px;
            padding: 0 0 30px;

            h2 {
                line-height: 50px;

                &::before {
                    min-height: 84px;
                }
            }
        }

        .frame {
            max-width: 850px;
            position: relative;
            margin-top: -5px;
        }

        .content {
            padding: 30px;
        }
    }

    // ------- SECTION 4 --------
    .section4 {
        .content {
            display: grid;
            justify-items: center;
        }

        .frame {
            max-width: 850px;
            position: relative;
            margin-top: -5px;
        }

        .text {
            padding: 30px;
        }
    }

    // ------- CARD --------
    .card {
        display: grid;
        margin-bottom: 60px;
        grid-gap: 40px;
        align-items: center;
        justify-items: center;

        .right {
            h2 {
                padding-right: 150px;
            }
        }

        .left, .right {
            align-self: center;
            justify-self: center;
        } 

        .mobileHide {
            display: none;
        }
    }
}

/**********************************
    @Media queries
**********************************/

// Mobile
@include viewport(mobile) {
    .seopage {
        .section {
            padding: 60px 0;
        }
        // ------- CARD --------
        .card {
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
            margin-bottom: 120px;
        }

        .mobileHide {
            display: none;
        }
    }
}

//Tablet
@include viewport(tablet) {
    .seopage {
        padding-top: 100px;

        .section {
            padding: 120px 0;
        }

        .card {
            .mobileHide {
                display: block;
            }
        }

        .buttonContainer {
            margin: 0 20px;
        }

        // ------- SECTION 1 --------
        .section1 {
            .container {
                padding: 0 50px;
            }

            .heading {
                position: absolute;
                margin-top: 0;
                bottom: 30px;
                left: 50px;
            }
    
            .content {
                padding: 0 50px;
                // margin-top: -130px;
            }
    
            .below {
                display: grid;
                // grid-template-columns: 2fr 1fr;
                margin-top: 30px;
            }
    
            .button {
                margin-top: 20px;
            }
        }

        // ------- SECTION 2 --------
        .section2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: end;

            .contentContainer {
                .side {
                    width: 100%;
                }

                .content {
                    width: 725px;
                }
            }
    
            .content {
                padding: 30px 40px;
            }
        }

        // ------- SECTION 3 --------
        .section3 {
            .heading {
                width: 50%;
                padding: 0 0 80px;
            }
    
            .picture {
                display: grid;
                justify-items: center;
            }
    
            .frame {
                margin-top: -200px;
            }

            .content {
                padding: 40px 60px;
            }
        }

        // ------- SECTION 4 --------
        .section4 {
            padding: 140px 0;
            .frame {
                margin-top: -125px;
            }

            .text {
                padding: 40px 60px;
            }
        }

        // ------- CARD --------
        .card {
            margin-bottom: 200px;
            grid-gap: 80px;

            .mobileHide {
                display: block;
            }
        }
    }
}

// Desktop
@include viewport(desktop) {
    .seopage {
        // ------- SECTION 2 --------
        .section2 {
            .content {
                padding: 6% 250px 6% 70px;
            }
        }
    }
}