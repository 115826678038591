@import '~shared/styles/config';

.articles_sidebar {
    overflow: hidden;
    margin-bottom: 60px;

    .border {
        margin-bottom: 0;
    }

    a {
        color: #fff;
        transition: color ease-in-out 0.15s;

        &:hover {
            color: $brand-higlight;
        }

        .read_more {
            color: white;

            .arrow {
                margin-left: 8px;
            }

            &:hover {
                color: white;

                .arrow {
                    color: $brand-higlight;
                }
            }
        }
    }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .read_more {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0;
            @include font-regular();
        }
    }

    .content {
        background: #1e2c37;
        padding: 10px;
    }

    .author_date {
        text-transform: uppercase;
        color: #979797;
        font-size: 14px;
        letter-spacing: 0.05em;
    }

    .row {
        padding: 20px 10px 10px;
        background-color: #1e2c37;
    }

    .sidebar_content {
        padding: 8px;
        padding-bottom: 16px;

        h3 {
            @include font-regular();
            font-size: 18px;
            margin-top: 4px;
        }
    }

    .img_container {
        position: relative;

        .play_btn {
            position: absolute;
            top: 30%;
            left: 42%;
        }

        .play {
            width: 50px;
        }
    }
}

.articles_ad {
    margin-bottom: 50px;

    .row {
        margin-top: 30px;
    }
}
