@import '~shared/styles/config';


.tags {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 0;
    margin: 0;
    padding: 0;
    // max-width: 11px;
    white-space: nowrap;

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        padding: 1px 5px 1px 10px;
        // margin-right: -6px;
        background: #fff;
        color: $www-primary;
        border-right: 5px solid $www-primary;
        // max-width: 2px;
        margin-top: 1px;
        overflow: hidden;
        transition: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        font-size: 14px;
        text-transform: lowercase;
        width: 24px;
        transition: width 0.5s;
        // font-size: 0;

        // &:before {
        //     content: "";
        //     float: left;
        //     position: absolute;
        //     top: 0;
        //     left: -7px;
        //     width: 0;
        //     height: 0;
        // }

        // &:first-child {
        //     max-height: 30px;
        //     border-right-width: 5px;
        //     max-width: 2px;
        //     background: #2980b9;
        //     font-size: 12px;
        // }

    }

    &:hover {
        max-width: 500px;

        li {
            width: 100px;

            a:hover {
                text-decoration: underline;
            }

         }
    }

}