@import '~shared/styles/config';
@import '../../../../shared/styles/default';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

.layout {
    display: block;

    &.japan {
        font-family: $font-family-ja;

        // --font-family: 'Noto Sans JP', sans-serif;
        --font-weight-light: 300;
        --font-weight-regular: 300;
        --font-weight-medium: 500;
        --font-weight-semibold: 500;
    }
}
