@import '~shared/styles/config';

.item {
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #fff;
    @include font-semibold();
    font-size: 15px;
    margin-left: 40px;
    margin-bottom: 7px;

    @media only screen and (max-width: $max-1800) {
        margin-left: 25px;
    }

    @media only screen and (max-width: $max-1440) {
        font-size: 14px;
        margin-left: 20px;
    }

    @media only screen and (max-width: $max-1280) {
        @include font-light();
        font-size: 35px;
        letter-spacing: 0.1em;
        margin-left: initial;
    }

    @media only screen and (max-width: $max-768) {
        font-size: 32px;
        margin-top: 20px;
    }

    .bar {
        // margin-top: -6px;
        border-top: 1px solid $www-primary;
        margin: -7px 0 6px -1px;
        width: 0;
    }

    .text {
        display: flex;
        align-items: center;

        .title {
            // To make it look right in IE, otherwise 100% would work
            flex: 0 1 auto;

            @media only screen and (max-width: $max-1280) {
                flex: 0 1 100%;
            }
        }

        .logo {
            font-size: 14.5px;

            @media only screen and (max-width: $max-1280) {
                flex: 0 0 22px;
                font-size: 25px;
                text-align: center;
            }

            .icon {
                // font-size: 14px;
                color: $color-font-gray;
                padding-left: 3px;

                &.external {
                    color: #256c9b;
                    transition: 0.2s ease-in-out;
                }

                &.mobileView {
                    display: none;

                    @media only screen and (max-width: $max-1280) {
                        display: inline-block;
                    }
                }
            }
        }
        // }
    }

    &.selected {
        .bar {
            width: 100%;
        }

        @media only screen and (max-width: $max-1280) {
            .bar {
                width: 0;
            }

            .title {
                margin-top: auto;
            }
        }
    }

    &:hover {
        .bar {
            width: 100%;
            transition: all ease-in-out 0.15s;

            @media only screen and (max-width: $max-1280) {
                width: 280px;
            }

            @media only screen and (max-width: 421px) {
                width: 211px;
            }
        }

        .text .logo .icon.external {
            color: $www-primary;
            transition: 0.2s ease-in-out;
        }
    }

    &.colorTheme {
        .bar {
            border-top: 1px solid $www-theme-highlight;
        }

        .text .logo .icon.external {
            color: $www-theme-highlight;
            opacity: 0.65;
        }

        .text:hover {
            .logo .icon.external {
                opacity: 1;
            }
        }
    }
}
