@import '~shared/styles/config';

.bottomPromo {
    padding-bottom: 120px;
    margin-top: 120px;
    text-align: center;

    h2 {
        line-height: 1.2;
    }

    .btn {
        margin-top: 30px;
    }

    /******************************
        @media queries
    ****************************/
    @include viewport(tablet) {
        padding-bottom: 200px;
        margin-top: 200px;

        .btn {
            margin-top: 50px;
        }
    }

    @include viewport(desktop) {
        padding-bottom: 16vw;
        margin-top: 12vw;
    }
}
