@import '~shared/styles/config';

.pilot_resources {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    padding-top: 180px;
    padding-bottom: 100px;
    background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/player-resources.png');

    .content {
        position: relative;
    }

    .text {
        margin-top: 60px;

        h1,
        p {
            @include font-light();
        }

        h1 {
            font-size: 6.5vw;
            line-height: 1.2;
            letter-spacing: 0.09em;
        }

        p {
            width: 100%;
            max-width: 825px;
            margin: 0 auto;
            margin-top: 45px;
            margin-bottom: 75px;
        }
    }

    .resources {
        // @include row;

        .resource {
            margin-top: 20px;
            text-align: left;

            img {
                width: 100%;
                transition: 0.2s ease-in-out;

                &:hover {
                    filter: brightness(1.2);
                }
            }

            h2 {
                font-size: 22px;
                text-transform: none;
                margin-top: 10px;
                @include font-regular();
            }

            p {
                margin: 0;
                margin-top: 5px;
                font-size: 18px;
                margin-bottom: 20px;

                &:first-of-type {
                    color: #979797;
                    margin-bottom: 15px;
                    @include font-medium();
                }
            }

            a {
                color: #979797;
                font-size: 16px;
                text-transform: uppercase;
                transition: 0.2s ease-in-out;
                display: flex;
                align-items: center;

                span {
                    display: flex;
                }

                svg {
                    font-size: 11px;
                    margin-left: 5px;
                }

                &:hover {
                    color: #fff;
                }

                @include font-medium();
            }
        }

        .border {
            width: 100%;
            height: 1px;
            background: #2d363d;
            margin: 50px 15px;
            display: none;
        }
    }
}

@include viewport(desktop) {
    .pilot_resources {
        padding-top: 180px;
        position: relative;

        .content {
            .text {
                h1 {
                    font-size: 60px;
                    line-height: 1;
                }

                p {
                    font-size: 22px;
                }
            }
        }
    }
}

@include viewport(tablet) {
    .pilot_resources {
        .resources {
            .border {
                display: block;
            }
        }
    }
}

@include viewport(mobileMax) {
    .pilot_resources {
        padding-top: 80px;
    }
}
