@import '~shared/styles/config';

.language {
    color: #fff;
    overflow: hidden;
    display: inline-flex;
    font-size: 15px;
    letter-spacing: 0.05em;
    z-index: 24;
    cursor: pointer;

    .selected {
        display: none;
        margin-left: 10px;
        text-transform: uppercase;
        @include font-semibold();

        &:focus {
            outline: none;
        }
    }

    .globe {
        transition: 0.2s ease-in-out;
        fill: #30b2e6;
    }

    ul {
        display: inline-block;
        transition: 0.2s ease-in-out;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 10px;
        }

        a {
            color: #fff;
            transition: 0.2s ease-in-out;
        }

        .lang_text {
            display: none;
        }

        .lang_code {
            display: block;
            text-transform: uppercase;
        }

        .active a,
        a:hover {
            color: $www-primary !important;
        }
    }

    &:hover,
    &:focus {
        .selected .icon {
            color: $www-primary;
        }

        ul {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            li:hover {
                color: white;
            }
        }

        .globe {
            filter: brightness(120%);
        }
    }

    // Color Theme
    &.colorTheme {
        ul .active a,
        ul a:hover {
            color: $www-theme-highlight !important;
        }

        svg path {
            fill: $www-theme-highlight;
        }
    }
}

@include viewport(tablet) {
    .language {
        width: initial;
        position: relative;
        overflow: visible;

        .selected {
            display: block;
        }

        ul {
            position: absolute;
            display: block;
            transform: translateY(10px);
            opacity: 0;
            visibility: hidden;
            background: #212121; // TODO: Change background when moved to Navigation
            padding: 25px 20px;
            left: auto;
            right: 0;
            width: 118px;
            top: 31px;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     top: -5px;
            //     right: 30px;
            //     width: 0;
            //     height: 0;
            //     border-left: 5px solid transparent;
            //     border-right: 5px solid transparent;
            //     border-bottom: 5px solid $www-primary;
            // }

            // Prevent opacity flicker on hover
            &::after {
                content: '';
                position: absolute;
                top: -40px;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            li {
                display: block;
                margin-top: 15px;
                margin-left: 0;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            .lang_text {
                display: block;
            }

            .lang_code {
                display: none;
            }
        }

        // Color theme
        &.colorTheme {
            ul::before {
                border-bottom-color: $www-theme-highlight;
            }
        }
    }
}
