@import '~shared/styles/config';

.grid {
    display: grid;
    padding: 20px 0;
    grid-gap: 20px;
}

.grid2 {
    grid-template-columns: 1fr 1fr;
}

.grid3 {
    grid-template-columns: 1fr 1fr;
}

.grid2_1 {
    grid-template-columns: 1fr;
}

/**********************************
    @Media queries
**********************************/

// Mobile up
@include viewport(mobile) {
    .grid3 {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

// Tablet up
@include viewport(tablet) {
    .grid {
        padding: 50px 0;
    }

    .grid2_1 {
        grid-template-columns: 2fr 1fr;
    }
}

// Desktop up
@include viewport(desktop) {
    .grid {
        grid-gap: 30px;
    }
}