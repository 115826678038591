@import '~shared/styles/config';

.page {
    background-image: url('https://webimg.ccpgamescdn.com/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg');
    background-color: #000;
    background-repeat: repeat;
    // background-attachment: fixed;
    background-size: auto;
    padding: 180px 70px 0;
    position: relative;

    &.japan {
        font-family: $font-family-ja;
    }

    .logo {
        margin-top: 60px;
        position: absolute;
        top: 0;

        @include viewport (mobileMax) {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .language {
        position: absolute;
        top: 0;
        right: 70px;
        margin-top: 70px;

        @include viewport (tabletmax) {
            display: none;
        }
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 90vh;
        background-color: #000;

        @include bg-img;
    }

    .dynamic_section:not(:first-of-type) {
        padding: 80px 0;
    }

    @include viewport (desktopMax) {
        background-size: unset;
    }

    @include viewport(tabletmax) {
        .bg {
            background-color: rgba(#000, 0.2);
            background-blend-mode: overlay;
        }
    }

    @include viewport (mobileMax) {
        padding: 80px 0;
        min-height: 75vh;

        .dynamic_section:not(:first-of-type) {
            padding: 40px 0;
        }
    }

    .cards_wrapper {
        margin-top: 65px;
    }

    .cta {
        margin: 10px auto;
        padding: 0 30px;
    }
}
