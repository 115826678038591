@import '~shared/styles/config';

.hr {
    background-color: rgba($www-steel-gray, 0.5);
    margin: 30px 0;
}

.top {
    @include hr-top();
}

.bottom {
    @include hr-bottom();
}
