@import '~shared/styles/config';

.content {
    text-align: center;

    .logo {
        margin: 100px 10px 70px;

        @media only screen and (max-width: '1080px') {
            width: 120px;
            margin: 20px 10px 10px;
        }
    }

    .heading {
        margin: 0 65px;

        @media only screen and (max-width: '1080px') {
            margin: 0;
        }
    }

    .tagline {
        margin: 20px 0 30px;
        text-transform: unset;

        @include viewport(mobile) {
            margin: 20px 110px 70px;
        }

        @media only screen and (max-width: '1080px') {
            font-size: 22px;
        }
    }

    .videoContainer {
        padding: 40px;
        padding-bottom: 40px;
        margin: 0 -15px;
        background-color: #212121;

        @media only screen and (max-width: '1080px') {
            padding: 20px 20px 20px 20px;
        }

        .video {
            min-height: 680px;
            background-image: url(https://images.ctfassets.net/7lhcm73ukv5p/68FO4PJESTo6E29UR0Vj0i/dffe0bcb3a18c86211a90de680d9cfa3/ccplease-ccp-foxcat-meta-image.jpg);
            background-repeat: no-repeat;
            background-size: cover;

            @media only screen and (max-width: '1290px') {
                min-height: 500px;
            }

            @media only screen and (max-width: '800px') {
                min-height: 350px;
            }

            h1 {
                padding-top: 300px;
                font-size: 40px;

                @media only screen and (max-width: '1290px') {
                    padding-top: 220px;
                    font-size: 30px;
                }

                @media only screen and (max-width: '800px') {
                    padding-top: 140px;
                    font-size: 30px;
                }
            }
        }


        .bottom {
            display: flex;
            flex-direction: row;

            .left {
                flex: 1 1 50%;
                text-align: left;

                .text {
                    text-transform: uppercase;
                    font-size: 20px;
                    display: none;

                    @include viewport(tablet) {
                        display: block;
                    }
                }

                .mobile {
                    display: block;
                    text-transform: uppercase;

                    @include viewport(tablet) {
                        display: none;
                    }
                }
            }

            .right {
                display: flex;
                flex: 1 1 50%;
                align-items: center;
                justify-content: flex-end;

                .social {
                    text-align: left;
                    float: left;
                    // width: 100%;
                    display: block;

                    .share {
                        // display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        font-size: 20px;
                        float: left;
                        margin: 0;
                        padding: 0;
                        display: none;

                        @include viewport(tablet) {
                            display: flex;
                        }
                    }

                    .icon {
                        float: right;
                        display: block;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    @include viewport(tablet) {
        .videoContainer {
            margin: unset;
        }
    }

    .details {
        padding: 30px;
        float: right;
        color: #777;
    }
}
