@import '~shared/styles/config';

.warning {
    display: block;
    width: 100%;
    background: #ffc700;
    z-index: 3434234;
    position: absolute;
    padding: 10px 0;
    line-height: 22px;
    color: #bd0000;

    .container {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 20px;

            svg {
                color: #bd0000;
                height: 30px;
                width: 30px;
            }
        }
    }

    h3,
    p {
        margin: 0;
        padding: 0;
        color: #1f1f1f;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 16px;

        b {
            @include font-semibold();
        }
    }
}
