@import '~shared/styles/config';

.jumbotron {
    text-align: center;

    @include viewport(desktop) {
        text-align: right;
    }

    color: #fff;
    position: relative;
    padding-top: 100px;

    @include bg-img;

    background-image:
        linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
        ),
        url('https://images.ctfassets.net/7lhcm73ukv5p/3gMnJL9Fyemah428xjZTq3/deaac463a2ceca17ad91732590729fa4/invasion_still.jpg');

    @include viewport(mobileMax) {
        background-image:
            linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
            ),
            url('https://images.ctfassets.net/7lhcm73ukv5p/AZI9pQcqzZlE7WcjGAXsj/ae03143ff71be290a7eb76f705fba47d/invasion-mobile-bg.jpg');
    }

    @include viewport(desktop) {
        background-image: url('https://images.ctfassets.net/7lhcm73ukv5p/3gMnJL9Fyemah428xjZTq3/deaac463a2ceca17ad91732590729fa4/invasion_still.jpg');
    }

    .jumbosection {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .jumbo_logo {
        display: none;
    }

    .jumbocontainer {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 60px;
    }

    .gradient {
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            // bottom: -400px;
            bottom: -22vw;
            width: auto;
            min-width: 100%;
        }
    }

    .content {
        position: relative;
        text-align: center;

        @include viewport(desktop) {
            text-align: right;
        }
    }

    .text {
        margin-top: 60px;
        margin-bottom: 10px;
        text-align: center;

        @include viewport(desktop) {
            text-align: right;
        }

        padding-bottom: 20px;

        .placeholder {
            visibility: hidden;
        }

        .innerText {
            text-align: left;
            display: inline-block;
        }

        .center {
            text-align: center;
            width: unset !important;
        }

        .flip {
            display: inline-block;
            transform: rotateY(180deg);
        }

        h1 {
            font-size: 4.8vw;
            letter-spacing: 0.03em;
            @include font-semibold();
        }

        h2 {
            font-size: 4.2vw;
            letter-spacing: 0.03em;
            @include font-light();
        }

        p {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            margin-top: 28px;
            // margin-bottom: 56px;
            @include font-light();
        }

        .border {
            width: 340px;
            background: #fff;
            height: 1px;
            margin: 0 auto;
            margin-top: 20px;
        }
    }

    .rdy {
        opacity: 1;
    }
}

.fade-appear {
    opacity: 0.01;
}

.fade {
    -webkit-animation: fadein 5s;
    animation: fadein 4s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.quotes {
    position: relative;
    opacity: 1;
    margin: 20px auto 30px;
    // height: 50px;
    text-align: center;
    transition: opacity 2.5s;
    padding: 0 15px;

    &.hidden {
        opacity: 0;
    }

    div {
        display: inline-block;
        vertical-align: middle;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    p {
        margin: 0;
        vertical-align: middle;
    }

    img {
        margin-bottom: 15px;
    }
}

@include viewport(desktop) {
    .jumbotron {
        padding-top: 160px;
        // position: fixed;
        width: 100%;
        height: 100vh;
        // margin-top: -100vh;

        .content {
            .text {
                h1 {
                    font-size: 68px;
                    line-height: 1;
                }

                h2 {
                    margin-top: 10px;
                    font-size: 48px;
                }

                p {
                    font-size: 24px;
                }
            }
        }
    }

    .quotes {
        position: absolute;
        bottom: 45px;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
    }
}

@include viewport(IE) {
    .jumbotron {
        .content {
            .text {
                margin-top: 60px;
            }
        }
    }
}

@include viewport(mobileMax) {
    .jumbotron {
        // background-image: url('https://web.ccpgamescdn.com/aws/eveonline/images/hero-mobile.jpg') !important;

        .jumbocontainer {
            padding-bottom: 20px;
        }

        .jumbo_logo {
            display: block;
        }

        .logo {
            left: 50%;
            transform: translateX(-50%);
        }

        .content {
            .text {
                h1 {
                    font-size: 6vw;
                }

                h2 {
                    font-size: 5vw;
                    margin-top: 5px;
                }

                p {
                    letter-spacing: 0.025em;
                    margin-top: 18px;
                    // margin-bottom: 36px;
                }

                .border {
                    width: 100%;
                }

                .quotes {
                    margin-bottom: 30px;
                }
            }
        }

        .gradient {
            display: none;
        }
    }
}

@include viewport(IE) {
    .jumbotron {
        position: relative;
        margin-top: 0;
    }
}

@include viewport(xsMax) {
    .jumbotron {
        padding-top: 80px;
    }
}
