.content {
    text-align: center;
    // max-width: 2700px;
    // margin: 0 auto;

    .hero {
        // background-size: contain; // 300%;
        background-size: 1920px;

        @media only screen and (max-width: '768px') {
            background-position-x: 100%;
            background-size: 300%;
        }

        @media only screen and (min-width: '1920px') {
            background-size: contain;
        }

        @media only screen and (min-width: '3000px') {
            background-size: contain;
            background-position-y: -7vw;
        }

        @media only screen and (min-width: '3500px') {
            background-size: contain;
            background-position-y: -15vw;
        }
    }

    .bottom {
        background-size: contain; // 300%;

        @media only screen and (max-width: '1080px') {
            background-position-x: 100%;
            background-size: 150%;
        }
    }
}
