@import '../articles/ArticlesShared';

/********************************************
/Articles/Category
********************************************/

.search {
    float: right;
    padding-bottom: 10px;

    @include viewport(mobileMax) {
        margin-top: 20px;
        float: initial;
    }

    .searchBox {
        float: right;
        margin-top: -20px;
        margin-right: 30px;

        @include viewport(mobileMax) {
            float: initial;
            margin-top: 0;
            text-align: right;
        }
    }

    .icon {
        width: 30px;
        height: 30px;
        background: #00aeff;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        border: none;
        color: #fff;
        float: right;
        z-index: 20;
        position: absolute;

        @include viewport(mobileMax) {
            float: initial;
            position: absolute;
        }

        :hover {
            color: #979797;
        }

        &.end {
            float: left;
            left: 27px;
            z-index: 0;
            opacity: 0;
            position: relative;

            @include viewport(mobileMax) {
                position: absolute;
            }
        }

        i {
            padding: 3px;
        }
    }

    input {
        border: none;
        position: relative;
        background: #00aeff;
        color: #fff;
        padding: 5px;
        width: 1px;
        height: 30px;
        opacity: 0;
        z-index: 2;
        transition: width 0.2s ease-in-out;
        outline-width: 0;
        transform: translateX(14px);

        &:focus {
            background: #00aeff;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            background: #00aeff;
            width: 4px;
            height: 100%;
        }
    }

    ::placeholder {
        color: #fff;
        opacity: 0.7;
    }

    &.active {
        input {
            opacity: 1;
            width: 200px;

            @include viewport(mobileMax) {
                width: 98%;
            }
        }

        .end {
            opacity: 1;
        }
    }
}

.articles_list {
    background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/stars.jpg');
    background-size: cover;
    background-attachment: fixed;

    h3 {
        font-size: 24px;
        text-transform: uppercase;
        margin: 12px 0;
        width: 100%;
        max-width: 470px;
        @include font-medium();
    }

    .article_heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h2 {
            font-size: 22px;

            @include viewport(tablet) {
                font-size: 26px;
            }
        }
    }

    .row {
        margin-top: 35px;

        @include viewport(tablet) {
            margin-top: 55px;
        }

        .col {
            &:last-of-type {
                @include viewport(tablet) {
                    align-self: center;
                    padding-top: 0;
                }

                @include viewport(desktop) {
                    padding-top: 40px;
                    align-self: stretch;
                }
            }

            img {
                object-fit: cover;
                height: 100%;
            }

            &:first-of-type {
                max-height: 350px;
            }

            @include viewport(desktop) {
                &:first-of-type {
                    height: 350px;
                }
            }
        }
    }

    .result_metadata {
        margin-top: -10px;
        height: 20px;

        > div {
            display: inline-block;

            @include viewport(mobileMax) {
                display: block;
            }
        }

        .highlight {
            @include font-semibold();
        }
    }

    .articles_container {
        opacity: 0;
        transition: all 0.1s ease-in-out;
        transform: translate3d(0, 20px, 0);

        &.active {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }
}
