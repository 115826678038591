@import '~shared/styles/config';

.bgVideo {
    @include fit;

    video {
        width: 100%;
    }

    &.fill video {
        min-height: 100%;
        min-width: 100%;
        width: auto;

        @include centerer;
    }
}

.poster {
    @include fit;

    img {
        width: 100%;
    }
}
