.card {
    position: relative;

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 20px 30px;
    }

    &__title {
        margin: 10px 0 0;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background-image: linear-gradient(0deg, rgba(#000, 0.9), transparent);
        background: linear-gradient(0deg, black 15%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;
    }
}
