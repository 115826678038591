
@import '~shared/styles/config';

.cardList {
    width: 1290px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @include viewport(max1440) {
        width: 100%;
        grid-gap: 16px;
    }

    @include viewport(mobileMax) {
        padding: 0 15px;
        grid-template-columns: 1fr;
    }
}
