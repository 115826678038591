@import '~shared/styles/config';

.btnWrapperSmall {
    margin-top: 15px;

    .smallBtn {
        background: $www-primary;
        color: #fff;
        padding: 15px 35px;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 5px;
        @include font-semibold();

        span {
            margin-right: 15px;
            padding-bottom: 5px;
        }

        svg {
            font-size: 30px;
        }
    }
}

.btnWrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    .primaryBtn {
        background: $www-primary;
        @include font-semibold();
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        padding: 25px 35px 25px 100px;
        position: relative;
        transition: 0.2s ease-in-out;
        margin-bottom: 10px;
        clip-path:
            polygon(
                0% 0,
                0 0%,
                100% 0%,
                100% 0,
                100% calc(100% - 10px),
                calc(100% - 10px) 100%,
                0 100%,
                0% calc(100% - 10px)
            );

        &:hover {
            background: #fff;
            color: $www-primary;

            .platform {
                color: gray;
            }
        }

        .dArrow {
            position: absolute;
            top: 25px;
            left: 25px;

            svg {
                font-size: 55px;
            }
        }

        .platform {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            padding-top: 5px;
            display: block;
            transition: 0.2s ease-in-out;

            svg {
                font-size: 130%;
                margin-right: 10px;
            }
        }

        &.small {
            padding: 15px;

            .dArrow {
                left: 20px;

                @include viewport(mobileMax) {
                    display: none;
                }

                svg {
                    font-size: 30px;
                }
            }
        }

        &.transparent {
            width: 100%;
            height: 84px;
            padding: 15px;
            background: white;
            position: relative;
            -webkit-clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0% calc(100% - 10px));
            clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0% calc(100% - 10px));
            border: 2px solid;

            &:hover {
                background: #30b2e6;
            }

            .dArrow {
                left: 20px;

                @include viewport(mobileMax) {
                    display: none;
                }

                svg {
                    font-size: 30px;
                }
            }

            &::before {
                content: '';
                width: 100%;
                height: 82px;
                background-color: rgb(33, 33, 33);
                padding: 2px;
                -webkit-clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0% calc(100% - 10px));
                clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0% calc(100% - 10px));
                display: block;
                position: absolute;
                top: -1px;
                left: 0;
                z-index: -1;
            }
        }
    }

    .secondary {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        padding-top: 5px;
        display: block;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;

        &:hover {
            color: #fff;
        }

        svg {
            font-size: 130%;
            margin-right: 10px;
        }
    }
}
