@import '~shared/styles/config';

.patchNotes {
    text-align: right;

    .link {
        font-size: 15px;
        font-weight: $font-regular;
    }
}
