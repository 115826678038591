@import '~shared/styles/config';

.hero {
    padding: 200px 0 100px 0;
    min-height: 600px;
    position: relative;
    background-color: rgba(#000, 0.1);
    background-blend-mode: overlay;

    h2 {
        margin-top: 10px;
        margin-bottom: 40px;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100px;
        background-image: linear-gradient(0deg, #000000, transparent);
        position: absolute;
        bottom: 0;
        left: 0;        
    }

    .buttonContainer {
        display: flex;
        // min-height: 150px;
        justify-content: left;
        
        > div, > a {
            margin-right: 20px;
        }
    }
}
